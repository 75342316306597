import {HomePageToolkit, HomePageCompanyLogo, HomePageStarredEntities, HomePageRecentlyVisited, HomePageTopVisited} from '@backstage/plugin-home';
import { Content, Page, InfoCard } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import LogoFull from '../Root/LogoFull';
import LogoIcon from '../Root/LogoIcon';
    
const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none'
  }
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#e20074',
  },
}));
    
export const HomePage = () => {
  const classes = useStyles();
  const { container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <LogoFull />
                  <span style={{ marginLeft: '40px', fontSize: '35px', fontWeight:'bolder' }}>HTPC Development Platform</span>
                </div>
              }
            />
            <Grid container item xs={12} justifyContent='center'>
              <HomePageSearchBar
                InputProps={{ classes: { root: classes.searchBarInput, notchedOutline: classes.searchBarOutline }}}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities title='My favourites'/>
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  tools={[
                    {
                      url: 'https://wikijs.test.t-sandbox.es/',
                      label: 'HTPC WikiJS',
                      icon: <LogoIcon />,
                    },
                    {
                      url: 'https://gitlab.test.t-sandbox.es/',
                      label: 'HTPC Gitlab',
                      icon: <LogoIcon />,
                    },
                    {
                      url: 'https://trello.com/b/5W1AAZqm/work-23',
                      label: 'Trello',
                      icon: <LogoIcon />,
                    },
                    {
                      url: 'https://nexus.test.t-sandbox.es/',
                      label: 'Nexus',
                      icon: <LogoIcon />,
                    },
                    {
                      url: 'https://harbor.test.t-sandbox.es/harbor/projects',
                      label: 'Harbor',
                      icon: <LogoIcon />,
                    },
                    {
                      url: 'https://argocd.test.t-sandbox.es/',
                      label: 'ArgoCD',
                      icon: <LogoIcon />,
                    },
                    {
                      url: 'https://grafana.test.t-sandbox.es/',
                      label: 'Grafana',
                      icon: <LogoIcon />,
                    },
                    {
                      url: 'https://sonarqube.test.t-sandbox.es/',
                      label: 'Sonarqube',
                      icon: <LogoIcon />,
                    },
                    {
                      url: 'https://app.snyk.io/org/davmargomez/projects?groupBy=targets&before&after&searchQuery=&sortBy=highest+severity&filters[Show]=&filters[Integrations]=&filters[CollectionIds]=',
                      label: 'Snyk',
                      icon: <LogoIcon />,
                    }
                  ]}
                  title='Useful Links'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageRecentlyVisited />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageTopVisited />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};